import React from 'react';
import { css } from '@emotion/react';

import styled, { scale, BREAKPOINTS } from '../styled';

import CenteredBox, { CenteredBoxSizes } from './CenteredBox';

const Layout = styled(CenteredBox)`
  margin-top: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-top: ${scale(2)};
  }
`;

const Body: React.FC<{ content: string }> = ({ content, ...props }) => {
  return (
    <Layout {...props} size={CenteredBoxSizes.S}>
      <div
        css={css`
          img {
            max-width: 100%;
            border-radius: ${scale(2)};

            margin-top: ${scale(2)};
          }
        `}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Layout>
  );
};

export default Body;
